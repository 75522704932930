import React, { useContext } from "react";
import { Box, Text, ResponsiveContext,Anchor } from "grommet";
import styled from "styled-components"
import { Link } from "gatsby";
import {useSelector} from "react-redux"
import { FaInstagram,FaFacebookF } from 'react-icons/fa'
const InternalLink = styled(Link)`
text-decoration: none;
:link {
  color: inherit;
}
:visited {
  color: inherit;
}
`

const textEng = [
  "About the initiative",
  "Parliament for the Future is a citizen’s initiative. It’s not affiliated with any political party or organization. There’s no financier, no commercial or other similar interests behind. The driving force is only to safeguard a livable planet for the generations to come.",
"Contact",
"Don’t hesitate to contact us by email if you have any questions: kontakt@riksdagenforframtiden.se",
"Parliament for the Future - Climate action now! 2021",
]

const textSwe=[
  "Om initiativet",
  "Riksdagen för framtiden är ett privat medborgarinitiativ. Det är inte knutet till något politiskt parti eller någon annan organisation. Det finns ingen finansiär och inga kommersiella eller andra liknande intressen bakom. Drivkraften är enbart att värna om en beboelig planet för våra efterkommande.",
  "Kontakt",
  "Har du frågor så kan du ta kontakt via e-post: kontakt@riksdagenforframtiden.se",
  "Riksdagen för framtiden - Klimatbeslut nu! 2021"
]

export const Footer = () => {
  const size = useContext(ResponsiveContext)
  const language = useSelector(state=> state.language.language);
  const textArray = language==="SWE"?textSwe:textEng;
  let dir = size === "small" || size === "xsmall" ? "column" : "row";
  let textSize = size === "small" || size === "xsmall" ? "xsmall" : "small";
  let headingSize = size === "small" || size === "xsmall" ? "small" : "medium";

  return (
    <Box
      pad="medium"
      tag="footer"
      direction="column"
      align="center"
      justify="center"
      border={{
        size: "xsmall",
        side: "top",
        color: "gray"
      }}
      style={{ zIndex: "3" }}
      gap="medium"
    >
      <Box align="start" justify="start" alignSelf="start" direction={dir} gap="medium">
        <InternalLink to="/contact">
          <Box direction="column" >
            <Text size={headingSize} weight="bold" color="darkGreen">
            {textArray[0]}
            </Text>
            <Text size={textSize} color="darkGreen">{textArray[1]}
            </Text>
          </Box>
        </InternalLink>
        <Box>
          <Text size={headingSize} weight="bold" color="darkGreen">
          {textArray[2]}
          </Text>
          <Text size={textSize} color="darkGreen">
          {textArray[3]}
          </Text>
        </Box>
        {size === "small" || size === "xsmall" ?        
         <Box direction="row" gap="small" >
           <a href="https://instagram.com/riksdagenforframtiden?utm_medium=copy_link">
          <FaInstagram color="darkGreen" size="25"/>
          </a>
          <a href="https://www.facebook.com/riksdagenforframtiden">
          <FaFacebookF color="darkGreen" size="25"/>
          </a>
        </Box>
        :
        <Box gap="small" pad="medium">
           <a href="https://instagram.com/riksdagenforframtiden?utm_medium=copy_link">
          <FaInstagram color="darkGreen" size="25"/>
          </a>
          <a href="https://www.facebook.com/riksdagenforframtiden">
          <FaFacebookF color="darkGreen" size="25"/>
          </a>
      </Box>}

      </Box>
      <Box border={{ color: "lightGreen", size: "small", side: "top" }} width="medium" />
      <Box>
        <Text size={textSize} color="lightGreen">{textArray[4]}</Text>
      </Box>
    </Box>
  )
};