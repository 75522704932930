import React from "react";

import "../styles/layout.css";
import * as styles from "./layout.module.css";
import { Footer } from "./footer"; 
import { Header1 } from "./header1";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header1 />

    <div className={styles.content}>{children}</div>
<Footer/>
  </>
);

export default Layout;
