import React,{useContext} from "react";
import { Box, Text,Button,ResponsiveContext } from "grommet";
import styled from "styled-components"
import { Link } from "gatsby";
import { useDispatch} from "react-redux"
import { changeLanguageState } from "../redux/slices/languageReducer";
import Flag from 'react-world-flags'
import {useSelector} from "react-redux"

const InternalLink = styled(Link)`
text-decoration: none;
:link {
  color: inherit;
}
:visited {
  color: inherit;
}
`

const textEng = [
  "Home",
  "Why?",
  "How does it work?",
  "About the initiative",
  "FAQ"
]

const textSwe=[
  "Hem",
  "Varför?",
  "Hur fungerar det?",
  "Om initiativet",
  "Frågor och svar",
]

const textEngSmall = [
  "Home",
  "Why?",
  "How?",
  "About",
  "FAQ"
]

const textSweSmall=[
  "Hem",
  "Varför?",
  "Hur?",
  "Om",
  "FAQ",
]
export const Header1 = () => {
  const dispatch = useDispatch()
  const size = useContext(ResponsiveContext)
  const language = useSelector(state=> state.language.language);
  let textArray = textSwe;
  if(size==="xsmall"){
    textArray = language==="SWE"?textSweSmall:textEngSmall
  }else{
    textArray = language==="SWE"?textSwe:textEng;
  }
  
 let textSize = size==="xsmall"?"small":"large";
 let gapSize = size==="xsmall"?"xsmall":"medium";
 let heightSize = size==="xsmall"?"60px":"80px";
 let flagSwe = size==="xsmall"?"16":"18";
 let flagEng = size==="xsmall"?"12":"14";
  return (
    <Box
      tag='header'
      direction='row'
      align='center'
      justify='between'
      pad={{ vertical: 'small' }}
      //elevation='medium'
      style={{ zIndex: '1' }}
    >
      <Box
        direction="row"
        align="center"
        justify="evenly"
        width="100%"
        height={heightSize}
        //pad={{ right: "xlarge" }}
        style={{ zIndex: "1" }}
        gap="small"
      >
        <Box width="xsmall"/>
        <Box
          direction="row"
          align="center"
          justify="between"
          width="48em"
          margin={{left:"-12px",right:"8px"}}
          gap={gapSize}
        >
          <InternalLink to="/">
            <Text size={textSize}weight="bold" color="darkGreen" >
            {textArray[0]}
            </Text>
          </InternalLink>
          <InternalLink to="/about">
            <Text size={textSize} weight="bold" color="darkGreen"  >
            {textArray[1]}
            </Text>
          </InternalLink>
          <InternalLink to="/contribute">
            <Text size={textSize} weight="bold" color="darkGreen" >
            {textArray[2]}
            </Text>
          </InternalLink>
          <InternalLink to="/contact">
            <Text size={textSize} weight="bold" color="darkGreen"  >
            {textArray[3]}
            </Text>
          </InternalLink>
          <InternalLink to="/faq">
            <Text size={textSize} weight="bold" color="darkGreen"  >
            {textArray[4]}
            </Text>
          </InternalLink>
        </Box>
        <Box width="xsmall">
            <Button onClick={()=>{dispatch(changeLanguageState({key:"language", value: "SWE"}))}}>
              <Flag code="swe" height={flagSwe}/>
            </Button >
            <Button onClick={()=>{dispatch(changeLanguageState({key:"language", value: "ENG"}))}}>
            <Flag code="gb" height={flagEng}/>
            </Button>
          </Box>
      </Box>
    </Box>

  )
};